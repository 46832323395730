import { ENGLISH_LOCALE_ID, SPANISH_LOCALE_ID, FRENCH_LOCALE_ID, GERMAN_LOCALE_ID, PORTUGEESE_LOCALE_ID, RUSSIAN_LOCALE_ID, JAPANESE_LOCALE_ID, ITALIAN_LOCALE_ID, DUTCH_LOCALE_ID, KOREAN_LOCALE_ID, TURKISH_LOCALE_ID, SWEDISH_LOCALE_ID, POLISH_LOCALE_ID, CHINESE_LOCALE_ID } from "constants/localization";
import filter from "lodash/filter";
export const DIALOG_DIRECTIONS = {
    ALIGN_TOP: "top",
    ALIGN_BOTTOM: "bottom"
};
export const LANGUAGES = {
    [ENGLISH_LOCALE_ID]: {
        id: ENGLISH_LOCALE_ID,
        label: "English",
        shortLabel: ENGLISH_LOCALE_ID,
        flag: "🇺🇸"
    },
    [SPANISH_LOCALE_ID]: {
        id: SPANISH_LOCALE_ID,
        label: "Español",
        shortLabel: SPANISH_LOCALE_ID,
        flag: "🇲🇽"
    },
    [FRENCH_LOCALE_ID]: {
        id: FRENCH_LOCALE_ID,
        label: "Français",
        shortLabel: FRENCH_LOCALE_ID,
        flag: "🇫🇷"
    },
    [GERMAN_LOCALE_ID]: {
        id: GERMAN_LOCALE_ID,
        label: "Deutsch",
        shortLabel: GERMAN_LOCALE_ID,
        flag: "🇩🇪"
    },
    [PORTUGEESE_LOCALE_ID]: {
        id: PORTUGEESE_LOCALE_ID,
        label: "Português",
        shortLabel: PORTUGEESE_LOCALE_ID,
        flag: "🇵🇹"
    },
    [DUTCH_LOCALE_ID]: {
        id: DUTCH_LOCALE_ID,
        label: "Nederlands",
        shortLabel: DUTCH_LOCALE_ID,
        flag: "🇳🇱"
    },
    [ITALIAN_LOCALE_ID]: {
        id: ITALIAN_LOCALE_ID,
        label: "Italiano",
        shortLabel: ITALIAN_LOCALE_ID,
        flag: "🇮🇹"
    },
    [RUSSIAN_LOCALE_ID]: {
        id: RUSSIAN_LOCALE_ID,
        label: "Pусский",
        shortLabel: RUSSIAN_LOCALE_ID,
        flag: "🇷🇺"
    },
    [JAPANESE_LOCALE_ID]: {
        id: JAPANESE_LOCALE_ID,
        label: "日本語",
        shortLabel: JAPANESE_LOCALE_ID,
        flag: "🇯🇵"
    },
    [KOREAN_LOCALE_ID]: {
        id: KOREAN_LOCALE_ID,
        label: "한국어",
        shortLabel: KOREAN_LOCALE_ID,
        flag: "🇰🇷"
    },
    [TURKISH_LOCALE_ID]: {
        id: TURKISH_LOCALE_ID,
        label: "Türkçe",
        shortLabel: TURKISH_LOCALE_ID,
        flag: "🇹🇷"
    },
    [SWEDISH_LOCALE_ID]: {
        id: SWEDISH_LOCALE_ID,
        label: "Svenska",
        shortLabel: SWEDISH_LOCALE_ID,
        flag: "🇸🇪"
    },
    [POLISH_LOCALE_ID]: {
        id: POLISH_LOCALE_ID,
        label: "Polski",
        shortLabel: POLISH_LOCALE_ID,
        flag: "🇵🇱"
    },
    [CHINESE_LOCALE_ID]: {
        id: CHINESE_LOCALE_ID,
        label: "繁體中文",
        shortLabel: CHINESE_LOCALE_ID,
        flag: "🇨🇳"
    }
};
export const MAIN_LANGUAGES_KEYS = [
    ENGLISH_LOCALE_ID,
    SPANISH_LOCALE_ID,
    PORTUGEESE_LOCALE_ID,
    FRENCH_LOCALE_ID,
    GERMAN_LOCALE_ID
];
export const getLanguagesByIds = (ids)=>{
    return filter(LANGUAGES, (language)=>ids.includes(language.id));
};
export const MAIN_LANGUAGES_VALUES = getLanguagesByIds(MAIN_LANGUAGES_KEYS);
